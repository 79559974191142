@keyframes homeBannerTextIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes homeBannerTextOut {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.home-title-xsm-inline-block {
  display: block;
}

#home-bnr-flip-txt-wrapper {
  color: var(--txtBrandColor);
  display: inline-block;
  position: relative;
  overflow: hidden;
  /* transition: width 0.5s; */
}

.home-bnr-flip-txt {
  display: none;
}

.home-bnr-flip-txt.home-banner-text-in {
  display: inline-block;
  animation: homeBannerTextIn 0.3s ease;
}
.home-bnr-flip-txt.home-banner-text-out {
  animation: homeBannerTextOut 0.3s ease;
}

@media (min-width: 360px) {
  .home-title-xsm-inline-block {
    display: inline-block;
  }
}
