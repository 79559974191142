@media (min-width: 481px) {
  #ftr-products-content {
    order: 1;
  }
  #ftr-company-content {
    order: 2;
  }
  #ftr-legal-content {
    order: 3;
  }
  #ftr-links-content {
    order: 4;
  }
}

@media (min-width: 768px) {
  #ftr-products-content {
    order: 1;
  }
  #ftr-legal-content {
    order: 2;
  }
  #ftr-company-content {
    order: 3;
  }
  #ftr-links-content {
    order: 4;
  }
}

/* @media (min-width: 1200px) {
  #ftr-products-content {
    order: 2;
  }
  #ftr-legal-content {
    order: 5;
  }
  #ftr-company-content {
    order: 3;
  }
  #ftr-links-content {
    order: 4;
  }
}

@media (min-width: 1400px) {
  #ftr-products-content {
    order: 2;
  }
  #ftr-legal-content {
    order: 3;
  }
  #ftr-company-content {
    order: 4;
  }
  #ftr-links-content {
    order: 5;
  }
} */
