/* for bolder */
@font-face {
  font-family: 'lts-bold';
  src: url(../src/Fonts/lt-superior-bold.otf);
  font-weight: normal;
  font-style: normal;
}
/* for 600 */
@font-face {
  font-family: 'lts-semi-bold';
  src: url(../src/Fonts/lt-superior-semi-bold.otf);
  font-weight: normal;
  font-style: normal;
}
/* for 500 */
@font-face {
  font-family: 'lts-medium';
  src: url(../src/Fonts/lt-superior-medium.otf);
  font-weight: normal;
  font-style: normal;
}
/* for 400 */
@font-face {
  font-family: 'lts-regular';
  src: url(../src/Fonts/lt-superior-regular.otf);
  font-weight: normal;
  font-style: normal;
}

/* for bolder */
@font-face {
  font-family: 'inter-bold';
  src: url(../src/Fonts/inter-bold.otf);
  font-weight: normal;
  font-style: normal;
}
/* for 600 */
@font-face {
  font-family: 'inter-semi-bold';
  src: url(../src/Fonts/inter-semi-bold.otf);
  font-weight: normal;
  font-style: normal;
}
/* for 500 */
@font-face {
  font-family: 'inter-medium';
  src: url(../src/Fonts/inter-medium.otf);
  font-weight: normal;
  font-style: normal;
}
/* for 400 */
@font-face {
  font-family: 'inter-regular';
  src: url(../src/Fonts/inter-regular.otf);
  font-weight: normal;
  font-style: normal;
}

:root {
  /* typography */
  --commonFontBold: 'inter-bold', sans-serif, Arial;
  --commonFontSemiBold: 'inter-semi-bold', sans-serif, Arial;
  --commonFontMedium: 'inter-medium', sans-serif, Arial;
  --commonFontRegular: 'inter-regular', sans-serif, Arial;
  --headingFontBold: 'lts-bold', sans-serif, Arial;
  --headingFontSemiBold: 'lts-semi-bold', sans-serif, Arial;
  --headingFontMedium: 'lts-medium', sans-serif, Arial;
  --headingFontRegular: 'lts-regular', sans-serif, Arial;

  /* utilities */
  --txtBrandColor: #635bff;
  --txtBlackColor: #212121;
  --txtHeadingColor: #0a2540;
  --txtDescriptionColor: #425466;
  --txtDarkGreyColor: #5f6367;
  --txtGreyColor: #a1a1a1;
  --txtFeatureGreen: #0cda7d;
  --txtFeatureAqua: #1bb8ff;
  --txtFeatureYellow: #ffd000;

  --bgBrandColor: #635bff;
  --bgBlackColor: #212121;
  --featureBlueColor: #1bb8ff;
  --featureGreenColor: #19d681;
  --featureYellowColor: #ffe056;
  --bgBrandLightColor: #9c83ff;
  --bgBluePowderColor: #f6f9fc;
  --bgDarkBtnColor: #0a2540;
  --secondaryWhiteColor: #fafaff; /* dull white color */
  --lightBorderColor: #f5f5f8;
  --greyBgLight: #f7f7f7;
  --activeGreyColor: #f2f2f2;
  --dboardBgColor: #f8f9fa;
  --footerBgColor: #f8f9fa;

  --saveColor: #43b730;
  --btnGreenBg: #30a752;
  --bgLightSun: #fff2c9;
  --bgLighterSun: #ffe594;
  --bgDrakSun: #ffcc29;

  --borderColor: #e0e0e0;
  --skyBorderColor: #e3e6f7;
  --lightBlueBorder: #cfd6fd;
  --greyShadowColor: #0000001a;
  --greyBorderColor: #00000021;
  --greyOverlay: #00000030;
  --logoGradient: linear-gradient(170deg, #635bff, #0079ff, #0090ff, #00a1fa, #19b0ec);
  --yellowGradient: linear-gradient(to right bottom, #ffcc29, #ffd34a, #ffd964, #ffdf7d, #ffe594);
  --multiGradient: linear-gradient(90deg, #635bff, #9c83ff 33.28%, #e151ff 50%, #fff759 120%);
  --stripedLightBg: linear-gradient(#dbdfea6c 1px, transparent 1px), linear-gradient(to right, #dbdfea6c 1px, #fff 1px);
  --dottedLightBg: radial-gradient(circle, #24242426 1px, transparent 1px);

  --elevationShadow1: 0 2px 12px #0000001a, 0 0 1px #00000021;
  --elevationShadow2: 0 2px 8px #00000021, 0 0 1px #00000030;
  /* --fgShadow: 0 1px 3px 0 #00000026, 0 0 0.5px 0 #0000004d; */
  /* --fgbrdShadow: 0 0 0 0.5px #00000033, 0px 0px 0.5px rgba(0, 0, 0, 0.18), 0px 3px 8px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1); */
  --cardShadow: 0px 1px 5px #00000021;
  --anchorHoverOpacity: 0.8;
  --logoHoverOpacity: 0.9;
  --hoverTransitionDuration: 0.25s;
  --layoutTransitionDuration: 0.3s;

  /* layout */
  --layoutMaxWidth: 1400px;
  --siteXAxisPadd: 16px;
  --siteYAxisPadd: 32px;
  --sideNavWidth: 320px;
  --sideNavYPadd: 12px;
  --headerHeight: 65px;
  --headerSeperationSpace: 8px;
  --navFontSize: 15px;
  --inputsYAxisPadd: 4px;
  --inputsLeftPadd: 12px;
  --inputsRightPadd: 4px;
  --buttonRadius: 4px;
  --cardRadius: 8px;
  --featureCardRadius: 16px;
  --rectangleRadius: 16px;

  /* dboard */
  --dboardXAxisPadd: 16px;
  --dboardHeaderHeight: 65px;
  --dboardSideNavWidth: 280px;
  --dboardSideMenusXPadd: 12px;
  --dboardSideMenuNegativeWidth: -280px;
  --dboardSideMenuSize: 20px;
  --dboardUserSettingsBtn: 50px;
  --dboardUserSettingsHeight: 66px;
  --dboardClosedBtnHeight: 36px;
  --dboardClosedSideNavWidth: 54px;
  --trialTCountHeight: 150px;
  --upgradableTCountHeight: 180px;
  --dboardContentMaxWidth: 1200px;
  --sectionSeperationSpace: 12px;
  --dboardSeperationSpace: 20px;
  --cardSeperationSpace: 8px;
  --dboardAccordsTPadd: 16px;
  --dboardAccordsXAxisPadd: 14px;
  --dboardAccordsBPadd: 16px;
  --dboardInnerCardXPadd: 14px;
  --dboardInnerCardYPadd: 14px;
}

body {
  margin: 0;
  padding: 0;
  color: var(--txtDescriptionColor);
  font-family: var(--commonFontRegular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*--- scroll bar properties starts ---*/
::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: #d0d0ec; /* color of the scroll thumb */
  border-radius: 4px; /* roundness of the scroll thumb */
  border: 0px; /* creates padding around scroll thumb */
}
div::-webkit-scrollbar {
  display: none;
}
/*--- scroll bar properties ends ---*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* accent color for inputs */
input[type='radio'],
input[type='checkbox'] {
  accent-color: var(--bgBrandColor);
}

input,
textarea {
  caret-color: var(--bgBrandColor);
}

input::placeholder,
textarea::placeholder {
  font-size: 1em;
  color: #0000007e;
}

input:disabled,
textarea:disabled,
select:disabled {
  color: #000000b9;
  background-color: #00000005;
}

object {
  outline: none;
}

h1 {
  font-family: var(--headingFontSemiBold);
}

h2,
h4 {
  font-family: var(--headingFontMedium);
}

/* removing margin bottom */
h1,
h2,
h4,
p {
  margin-bottom: 0px;
}

ul,
ol,
label {
  margin-bottom: unset;
}

h1 {
  padding-bottom: 16px;
}

h2 {
  padding-bottom: 8px;
}

h4 {
  font-size: 16px;
  padding-bottom: 4px;
}

h1,
h2,
h4 {
  color: var(--txtHeadingColor);
}

button {
  border: none;
  outline: none;
}
button:focus {
  outline: none;
}
.common-btn-radius {
  border-radius: var(--buttonRadius);
}

/* default link style */
a {
  color: inherit;
  text-decoration: none; /* removing text underline */
}

/* --------------------------For smartphones-------------------------- */
@media (min-width: 360px) {
  :root {
    --siteXAxisPadd: 20px;
    --dboardXAxisPadd: 20px;
    --dboardAccordsXAxisPadd: 16px;
  }
}

/* --------------------------For large mobile devices-------------------------- */
@media (min-width: 481px) {
  :root {
    --siteXAxisPadd: 24px;
    --dboardXAxisPadd: 24px;
    --dboardInnerCardXPadd: 16px;
    --dboardInnerCardYPadd: 16px;
    --dboardAccordsTPadd: 20px;
    --dboardAccordsXAxisPadd: 20px;
    --dboardAccordsBPadd: 20px;
  }
}

@media (min-width: 576px) {
  :root {
    --dboardAccordsTPadd: 24px;
    --dboardAccordsXAxisPadd: 24px;
    --dboardAccordsBPadd: 28px;
  }
}

/* --------------------------For tablet devices-------------------------- */
@media (min-width: 768px) {
  :root {
    --dboardAccordsXAxisPadd: 28px;
    --dboardAccordsBPadd: 32px;
    --dboardInnerCardXPadd: 20px;
    --dboardInnerCardYPadd: 20px;
  }
}

/* --------------------------For tablet devices (landscape)-------------------------- */
@media (min-width: 992px) {
  :root {
    --siteXAxisPadd: 35px;
    --siteYAxisPadd: 40px;
    --headerHeight: 70px;
    --navFontSize: 16px;
    --dboardSideNavWidth: 260px;
    --dboardSideMenuNegativeWidth: -260px;
  }
}

/* --------------------------For laptop devices-------------------------- */
@media (min-width: 1200px) {
  :root {
    --dboardSideNavWidth: 280px;
    --dboardSideMenuNegativeWidth: -280px;
    --dboardAccordsTPadd: 28px;
    --dboardAccordsXAxisPadd: 32px;
    --dboardAccordsBPadd: 36px;
  }
}

/* --------------------------For large laptop devices-------------------------- */
@media (min-width: 1400px) {
  :root {
    --dboardAccordsXAxisPadd: 36px;
    --dboardAccordsBPadd: 40px;
  }
}
