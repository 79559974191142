.common-font-bold {
  font-family: var(--commonFontBold) !important;
}
.common-font-semi-bold {
  font-family: var(--commonFontSemiBold) !important;
}
.common-font-medium {
  font-family: var(--commonFontMedium) !important;
}
.common-font-regular {
  font-family: var(--commonFontRegular) !important;
}

.heading-font-bold {
  font-family: var(--headingFontBold) !important;
}
.heading-font-semi-bold {
  font-family: var(--headingFontSemiBold) !important;
}
.heading-font-medium {
  font-family: var(--headingFontMedium) !important;
}
.heading-font-regular {
  font-family: var(--headingFontRegular) !important;
}

.site-fs-12 {
  font-size: 12px !important;
}
.site-fs-13 {
  font-size: 13px !important;
}
.site-fs-14 {
  font-size: 14px !important;
}
.site-fs-15 {
  font-size: 15px !important;
}
.site-fs-16 {
  font-size: 16px !important;
}
.site-fs-18 {
  font-size: 18px !important;
}
.site-fs-20 {
  font-size: 20px !important;
}
.site-fs-22 {
  font-size: 22px !important;
}
.site-fs-24 {
  font-size: 24px !important;
}
.site-fs-28 {
  font-size: 28px !important;
}
.site-fs-14-responsive {
  font-size: 14px;
}

.card-description-text {
  font-size: 15px;
  line-height: 1.45;
}

.txt-brand-color {
  color: var(--txtBrandColor) !important;
}
.txt-heading-color {
  color: var(--txtHeadingColor) !important;
}
.txt-description-color {
  color: var(--txtDescriptionColor) !important;
}
.txt-dark-grey-color {
  color: var(--txtDarkGreyColor) !important;
}
.txt-grey-color {
  color: var(--txtGreyColor) !important;
}
.saveColor {
  color: var(--saveColor) !important;
}
.txt-feature-green {
  color: var(--txtFeatureGreen) !important;
}
.txt-feature-aqua {
  color: var(--txtFeatureAqua) !important;
}
.txt-feature-yellow {
  color: var(--txtFeatureYellow) !important;
}
.inheritColor {
  color: inherit;
}

.bg-brand-color {
  background-color: var(--bgBrandColor);
}

.cursor-pointer {
  cursor: pointer;
}

.non-clickable,
.non-clickable:hover {
  cursor: default !important;
}

/* To prevent pointer events and element selection (for SVGs) */
.no-pointers {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* to remove unwanted line-height */
.no-line-height {
  line-height: normal !important;
}

.no-outline {
  outline: none !important;
}

/* to prevent text wrapping */
.no-text-wrap {
  white-space: nowrap;
}

.text-overflow-ellipsed {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-btn {
  border-radius: var(--buttonRadius);
}
/* for custom buttons hover and active effects */
.custom-btn:hover {
  filter: brightness(93%);
}
.custom-btn:active {
  filter: initial;
}

/* underlined link */
.underline-link {
  text-decoration: underline;
  text-underline-offset: 2px;
}
.underline-link:hover {
  text-decoration: underline;
}
.hover-link:hover {
  opacity: 0.85;
}

/* unordered list (for ul tag) */
.uList {
  padding-inline-start: 14px;
}

/* list (for li tag) */
.smallList {
  padding-inline-start: 4px;
  padding-bottom: 4px;
}

/* list (for pricing li tag) */
.planList {
  padding-inline-start: 10px;
  padding-bottom: 8px;
}
.planList::marker {
  content: '✔';
}

.blue-tick-list::marker {
  color: var(--txtBrandColor);
}
.light-tick-list::marker {
  color: var(--light);
}

.site-hr-line {
  border-color: var(--greyBorderColor);
  opacity: 1 !important;
}

.hr-border-white {
  border-top-color: #ffffff1a;
}

.txt-blue-gradient {
  display: inline-block;
  background: var(--logoGradient);
  -ms-background-clip: text;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: inherit;
}
.txt-site-gradient {
  display: inline-block;
  background: var(--multiGradient);
  -ms-background-clip: text;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: inherit;
}
.txt-sun-gradient {
  display: inline-block;
  background: var(--yellowGradient);
  -ms-background-clip: text;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: inherit;
}

.custom-input {
  outline: none;
  border: 1px solid var(--greyBorderColor);
  border-radius: var(--buttonRadius);
}

.custom-input:hover,
.custom-input:focus {
  border-color: var(--bgBrandColor);
}

.site-badge {
  line-height: normal;
  padding: 2px 5px;
  border-radius: 3px;
  text-decoration: none !important;
}

.elevation-shadow-2 {
  box-shadow: var(--elevationShadow2);
}

.banner-card-radius {
  border-radius: 16px;
}
.feature-card-radius {
  border-radius: var(--featureCardRadius);
}

.home-banner-canvas-wrapper > canvas {
  aspect-ratio: 4.86/3;
}
.package-banner-canvas-wrapper > canvas {
  aspect-ratio: 16/9;
}
.feature-canvas-wrapper > canvas {
  aspect-ratio: 2.24/2;
}
.loader-canvas-wrapper > canvas {
  aspect-ratio: 2/2;
}

.col-gap-16 {
  column-gap: 16px;
}

@media (min-width: 360px) {
  .display-ss-flex {
    display: flex;
  }
}

/* --------------------------Till large mobile devices-------------------------- */
@media (max-width: 480px) {
  .showOnLargeMobile {
    display: none !important;
  }
}

@media (min-width: 481px) {
  .hideOnLargeMobile {
    display: none !important;
  }
}

/* --------------------------For landscape devices-------------------------- */
@media (min-width: 992px) {
  .site-fs-14-responsive {
    font-size: 15px;
  }
}
